import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


window.onload = function() { 
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.graph1',
      start: 'center bottom',
    },              
  });

  tl.from('.graph1 .time-bar',{
    opacity:0,
    stagger:.2,
    ease: "power1.in"
  },'a')
  tl.from('.graph1 .time-bar-blue',{
    opacity:0,
    stagger:.2,
    ease: "power1.in"
  },'a')
  tl.from('.time-a-1', {
    innerText: 0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  },"b")
   tl.from('.time-a-2', {
    innerText: 0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  },"b")
  tl.from('.chunk-a', {
    opacity:0,
    y:50,
    ease: "power1.in"
  })
  tl.from('.time-a-3', {
    innerText: 0.0,
    snap: {innerText:0.1},
    duration: 2,
    ease: "power1.in"
  })

const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: '.graph2',
      start: 'center bottom',
    },              
  });

  tl2.from('.graph2 .time-bar',{
    opacity:0,
    stagger:.2,
    ease: "power1.in"
  },'a')
  tl2.from('.graph2 .time-bar-blue',{
    opacity:0,
    stagger:.2,
    ease: "power1.in"
  },'a')
  tl2.from('.time-b-1', {
    innerText: 0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  },"b")
   tl2.from('.time-b-2', {
    innerText: 0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  },"b")
  tl2.from('.chunk-b', {
    opacity:0,
    y:50,
    ease: "power1.in"
  })
  tl2.from('.time-b-3', {
    innerText: 0.0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  })

  const tl3 = gsap.timeline({
    scrollTrigger: {
      trigger: '.graph3',
      start: 'center bottom',
    },              
  });

    tl3.from('.chunk-c', {
    opacity:0,
    y:50,
    ease: "power1.in"
  })
  tl3.from('.time-c-3', {
    innerText: 0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  })  

   const tl4 = gsap.timeline({
    scrollTrigger: {
      trigger: '.graph4',
      start: 'center bottom',
    },              
  });

    tl4.from('.chunk-d', {
    opacity:0,
    y:50,
    ease: "power1.in"
  })
  tl4.from('.time-d-3', {
    innerText: 0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  })  
     tl4.from('.chunk-e', {
    opacity:0,
    y:50,
    ease: "power1.in"
  })
  tl4.from('.time-e-3', {
    innerText: 0,
    snap: {innerText:1},
    duration: 2,
    ease: "power1.in"
  }) 

   const tl5 = gsap.timeline({
    scrollTrigger: {
      trigger: '.graph5',
      start: 'center bottom',
    },              
  });

  tl5.from('.sim-1',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-2',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-3',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-4',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-5',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-6',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-7',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-8',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-9',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-10',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-11',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
  tl5.from('.sim-12',{
    opacity:0,
    
    duration:0.4,
    ease: "power1.in"
  })
}